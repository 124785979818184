"use strict";

// all func
// calendar func
// function initCalendar() {
//     const calendarEl = document.getElementById('calendar');
//     const calendar = new FullCalendar.Calendar(calendarEl, {
//         headerToolbar: {
//             left: '',
//             center: '',
//             right: ''
//         },
//         navLinks: true, // can click day/week names to navigate views
//         editable: true,
//         firstDay: 1,
//         selectable: true,
//         eventLimit: true, // allow "more" link when too many events
//         events: [
//             {
//                 title: '14:00 - 16:00',
//                 titleText: 'SV Elversberg vs. SSV Ulm 1846',
//                 info: 'Ursapharm Arena an der Kaiserlinde, Spiesen-Elversberg',
//                 time: 'Datum: 11.12.2021, 14:00 - 16:00 Uhr',
//                 plz: 'PLZ/Ort: 66583 Spiesen-Elversberg',
//                 location: 'Location: Ursapharm Arena an der Kaiserlinde',
//                 address: 'Adresse: LinkdenstraBe 1',
//                 url: '#',
//                 start: '2021-12-11',
//             },
//             {
//                 title: '17:00 - 19:00',
//                 titleText: 'FCS Tischtennis vs. Bergneustadt',
//                 info: 'Joachim-Deckarm-Halle, Saarbrücken',
//                 url: '#',
//                 start: '2021-12-11',
//             },
//             {
//                 title: '14:00 - 16:00',
//                 titleText: 'SV Elversberg vs. SSV Ulm 1846',
//                 info: 'Ursapharm Arena an der Kaiserlinde, Spiesen-Elversberg',
//                 url: '#',
//                 start: '2021-12-16',
//             },
//             {
//                 title: '14:00 - 16:00',
//                 titleText: 'SV Elversberg vs. SSV Ulm 1846',
//                 info: 'Ursapharm Arena an der Kaiserlinde, Spiesen-Elversberg',
//                 url: '#',
//                 start: '2021-12-22',
//             },
//             {
//                 title: '17:00 - 19:00',
//                 titleText: 'FCS Tischtennis vs. Bergneustadt',
//                 info: 'Joachim-Deckarm-Halle, Saarbrücken',
//                 url: '#',
//                 start: '2021-12-22',
//             },
//         ]
//     });
//     calendar.render();
//     calendar.updateSize();
// }
// calendar func end 
function initSlider() {
  if ($('.js-background-intro-slider').length > 0) {
    var swiper2 = new Swiper(".js-intro-slider", {
      loop: true,
      watchSlidesProgress: true,
      allowTouchMove: false,
      thumbs: {
        swiper: swiper1
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      }
    });
    var swiper1 = new Swiper(".js-background-intro-slider", {
      slidesPerView: 1,
      loop: true,
      thumbs: {
        swiper: swiper2
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }

  if ($('.js-news-slider').length > 0) {
    var swiper3 = new Swiper(".js-news-slider", {
      slidesPerView: 1,
      spaceBetween: 30,
      loop: true,
      centerInsufficientSlides: true,
      freeMode: {
        enabled: true,
        sticky: true
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
          spaceBetween: 15
        },
        490: {
          slidesPerView: 2
        },
        768: {
          slidesPerView: 3
        },
        991: {
          slidesPerView: 4
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 30
        }
      },
      navigation: {
        nextEl: ".js-news-slider-next",
        prevEl: ".js-news-slider-prev"
      }
    });
  }

  if ($('.js-gallery-slider-photo').length > 0) {
    var swiper4 = new Swiper(".js-gallery-slider-photo", {
      slidesPerView: 2,
      centeredSlides: true,
      spaceBetween: 15,
      // slidesPerView: 5,
      loop: true,
      navigation: {
        nextEl: ".js-gallery-next",
        prevEl: ".js-gallery-prev"
      },
      breakpoints: {
        575: {
          slidesPerView: 3
        },
        991: {
          slidesPerView: "auto"
        },
        1280: {
          spaceBetween: 30,
          slidesPerView: "auto"
        }
      }
    });
  }

  if ($('.js-gallery-slider-video').length > 0) {
    var swiper5 = new Swiper(".js-gallery-slider-video", {
      slidesPerView: 1,
      centeredSlides: true,
      spaceBetween: 15,
      // slidesPerView: 5,
      loop: true,
      navigation: {
        nextEl: ".js-video-next",
        prevEl: ".js-video-prev"
      },
      breakpoints: {
        575: {
          slidesPerView: 2
        },
        991: {
          slidesPerView: "auto"
        },
        1280: {
          spaceBetween: 30,
          slidesPerView: "auto"
        }
      }
    });
  }
}

function parallaxScroll() {
  var $elm = $('.js-parallax');
  var scrollTop = $(window).scrollTop() + $(window).height();
  var offsetTop = $elm.offset().top;
  var movePosition = (scrollTop - offsetTop) * 100 / $(window).height();

  if (scrollTop > offsetTop) {
    $elm.css('background-position', '50% ' + movePosition.toFixed() / 3 + '%');
  }
}

function adaptiveWPPAnel() {
  if ($('#wpadminbar').length > 0) {
    $('header').css('top', '32px');
  }
}

function checkScroll() {
  var $header = $('header');
  var scrollOfset = $(window).scrollTop();

  if (scrollOfset >= 50) {
    $header.addClass('fixed');
  } else {
    $header.removeClass('fixed');
  }
}

function toggleMenu() {
  $('.js-header').toggleClass('open-menu');
  $('body').toggleClass('fixed');
}

$('.js-burger').on('click', function () {
  toggleMenu();
});
$(window).on('scroll', function () {
  parallaxScroll();
  checkScroll();
}); // lightbox func

$('.js-light-box-item').on('click', function (event) {
  event.preventDefault();
  var imgURL = $(this).find('img').attr('src');
  var $lightbox = $('.js-transparent-overlay');
  $lightbox.find('.lightbox-img').html('<img src="' + imgURL + '" alt="alt">');
  $lightbox.addClass('active');
  $('body').addClass('fixed');
});

function closeLightBox() {
  $('.js-transparent-overlay').removeClass('active');
  $('.lightbox-img').html(' ');
  $('body').removeClass('fixed');
}

$('.js-transparent-overlay').on('click', '.js-close', function () {
  closeLightBox();
});
$(document).mouseup(function (e) {
  var div = $(".js-transparent-overlay .lightbox-img");

  if (!div.is(e.target) && div.has(e.target).length === 0) {
    closeLightBox();
  }
}); // lightbox func end
// accordion func

$('.events-list').on('click', '>li', function () {
  var $elm = $(this);
  var $eventBody = $elm.find('.event-info__body');

  if ($elm.hasClass('active')) {
    $elm.removeClass('active');
    $eventBody.slideUp(500);
  } else {
    $elm.addClass('active');
    $eventBody.slideDown(500);
  }
}); // accordion func end
// tab func 

$('[data-tab]').on('click', function (event) {
  event.preventDefault();
  var $elm = $(this);
  var tabId = $elm.data('tab');
  var $tabBody = $(tabId);
  $('[data-tab]').removeClass('active');
  $elm.addClass('active');
  $('.social-tab__body').removeClass('active');
  $tabBody.addClass('active');
}); // tab func end
// ready func

$(document).ready(function () {
  var d = new Date();
  $('.js-copyright').text(d.getFullYear());
  var year = Number(d.getFullYear()); // var swiper = new Swiper(".swiper-slide", {});

  if ($('#calendar').length > 0) {
    // initCalendar();
    // $("#calendar .fc-toolbar-chunk:last-child").append('<select class="select_month form-control"><option value="">Select Month</option><option value="1">January</option><option value="2">February</option><option value="3">March</option><option value="4">April</option><option value="5">May</option><option value="6">June</option><option value="7">July</option><option value="8">August</option><option value="9">September</option><option value="10">October</option><option value="11">November</option><option value="12" selected>December</option></select>');
    // $("#calendar .fc-toolbar-chunk:last-child").append('<select class="select_year form-control"><option>Select year</option><option selected value="'+ year +'">'+ year +'</option><option value="'+ Number(year + 1) +'">'+ Number(year + 1) +'</option><option value="'+ Number(year + 2) +'">'+ Number(year + 2) +'</option><option value="'+ Number(year + 3) +'">'+ Number(year + 3) +'</option></select>');
    // $(".select_month").on("change", function(event) {
    //     $('#calendar').fullCalendar('changeView', 'month', this.value);
    //     $('#calendar').fullCalendar('gotoDate', $(".select_year").val()+"-"+this.value+"-1");
    // });
    // $(".select_year").on("change", function(event) {
    //     $('#calendar').fullCalendar('changeView', 'month', this.value);
    //     $('#calendar').fullCalendar('gotoDate', this.value+"-"+$(".select_month").val()+"-1");
    // });
    $('.calendar >h2').prependTo($('#calendar'));
  }

  initSlider();
  adaptiveWPPAnel(); // resize func

  $(window).on('resize', function () {}); // resize func end
}); // ready func end